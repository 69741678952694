<template>
  <v-card>
    <v-card-title class="break-word-normal" primary-title>
      <div>
        <v-icon left>
          {{ common.icon }}
        </v-icon>
        {{ common.title }}
      </div>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1 text-break" v-html="common.warning" />
      <p
        v-if="!common.hideImportantInformation"
        class="red--text text-subtitle-1"
        v-text="common.importantInformation"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        :color="common.cancelButtonColor"
        text
        :disabled="commonDialogLoader"
        @click="$emit('close')"
      >
        {{ common.cancelText }}
      </v-btn>
      <v-btn
        :color="common.confirmationButtonColor"
        text
        :disabled="commonDialogLoader"
        :loading="commonDialogLoader"
        @click="common.confirmAction()"
      >
        {{ common.confirmationText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "CommonDialog",
  mixins: [sharedActions],
  data() {
    return {
      common: {
        icon: "$alert",
        title: this.$trans("confirmation_modal_header"),
        warning: this.$trans("confirmation_modal_warning"),
        importantInformation: this.$trans("no_way_to_undo_action"),
        confirmationText: this.$trans("remove"),
        cancelText: this.$trans("cancel"),
        confirmationButtonColor: "accent_light",
        cancelButtonColor: "light",
        hideImportantInformation: false,
      },
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "SET_CONFIRM_DIALOG") {
        if (mutation.payload.open) {
          this.setCommonDialog();
        }
      }
    });
  },
  mounted() {
    this.setCommonDialog();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    setCommonDialog() {
      this.common = {
        icon: "$alert",
        title: this.$trans("confirmation_modal_header"),
        warning: this.$trans("confirmation_modal_warning"),
        importantInformation: this.$trans("no_way_to_undo_action"),
        confirmationText: this.$trans("remove"),
        cancelText: this.$trans("cancel"),
        cancelButtonColor: "light",
        ...this.getConfirmDialog,
      };
    },
  },
};
</script>
